
@font-face {
    font-family: 'Montserrat';
    src: url('/fonts/montserrat-bold-webfont.woff2') format('woff2'),
         url('/fonts/montserrat-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: bold;

}

@font-face {
    font-family: 'Montserrat';
    src: url('/fonts/montserrat-regular-webfont.woff2') format('woff2'),
         url('/fonts/montserrat-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'Sumana';
    src: url('/fonts/sumana-bold-webfont.woff2') format('woff2'),
         url('/fonts/sumana-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;

}

@font-face {
    font-family: 'Sumana';
    src: url('/fonts/sumana-regular-webfont.woff2') format('woff2'),
         url('/fonts/sumana-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'Cinzel-normal';
    src: url('/fonts/cinzel-bold-webfont.woff2') format('woff2'),
         url('/fonts/cinzel-bold-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;

}


@font-face {
    font-family: 'Cinzel';
    src: url('/fonts/cinzel-regular-webfont.woff2') format('woff2'),
         url('/fonts/cinzel-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: bold;

}